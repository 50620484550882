<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <voucher-systems-form
      ref="form"
      :resources="resources"
      @is-abocard="hideDeleteButton = $event"
    />

    <!-- Go to old version -->
    <div class="flex justify-end mx-8 my-4">
      <router-link
        :to="{
          name: isNew ? 'voucher-systems-new-v1' : 'voucher-systems-edit-v1',
          params: $route.params
        }"
      >
        <el-button plain>
          {{ $t('common.forms.labels.old_flow') }}
        </el-button>
      </router-link>
    </div>
  </th-page-wrapper>
</template>

<script>
import VoucherSystemsForm from './components/form.vue'

export default {
  name: 'VoucherSystemsEdit',
  components: {
    VoucherSystemsForm
  },
  props: {
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      hideDeleteButton: true // true by default to prevent the delete button from being flickering
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'loyalty:voucher_systems',
        returnedPath: { name: 'external-systems-list' },
        isNew: this.isNew,
        excludeButtons: this.hideDeleteButton ? ['delete'] : []
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
