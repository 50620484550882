import baseModel from '@/models/base-model-new'

import { genInitialAction, ACTION_TYPES } from '../helpers'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        //add in order actions. initialize action if none.
        const data = response?.data || {}
        const actions = ACTION_TYPES.map((type) => {
          const item = data.actions?.find((action) => action.type === type)
          return item || genInitialAction(type)
        })

        return { ...data, actions }
      }
    }
    return baseModel.setup(model, 'voucherSystems', methods)
  }
}
