<template>
  <th-wrapper
    class="m-8"
    :title="$t('pages.voucher_systems.form.actions.label')"
    :subtitle="$t('pages.voucher_systems.form.actions.description')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row
        v-for="(action, index) in form.actions"
        :key="action.id"
        :gutter="20"
      >
        <el-col v-show="!(isCampaign && action.type === 'increment')">
          <el-form-item :prop="action.type" :label="actionText[action.type]">
            <remote-search-select
              :id="action.type"
              :model-value="action.product"
              popper-append-to-body
              resource="products"
              class="w-64"
              fetch-handler="getAll"
              :computed-fields="['custom_id', 'name']"
              :modify-query="modifyQuery"
              @resource-set="selectProduct(index, $event)"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: { RemoteSearchSelect },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    decrementOptional: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    isCampaign() {
      return safeGet(this.form, 'templates.0.attributes.is_campaign', false)
    },
    actionText() {
      const decrement = this.decrementOptional
        ? this.$t('pages.voucher_systems.form.actions.decrement_optional')
        : this.$t('pages.voucher_systems.form.actions.decrement')
      return {
        decrement,
        increment: this.$t('pages.voucher_systems.form.actions.increment'),
        create: this.$t('pages.voucher_systems.form.actions.create')
      }
    },
    rules() {
      if (this.decrementOptional) {
        return {}
      }
      return {
        decrement: {
          required: true,
          validator: this.validateProduct,
          trigger: ['blur', 'change']
        }
      }
    }
  },
  methods: {
    modifyQuery(q) {
      return {
        query: {
          limit: 50,
          deleted: false,
          sellable: true,
          exclude_system_products: true,
          type: [
            'product',
            'composed_product',
            'voucher',
            'linked',
            'linked_product',
            'variant',
            'variant_product'
          ],
          q,
          location_strict: this.form.locations?.length ? true : undefined,
          location: this.form.locations || null
        }
      }
    },
    selectProduct(index, product = {}) {
      this.form.actions[index].product = product.id
    },
    validateProduct({ field }, value, callback) {
      const action = this.form.actions.find(({ type }) => type === field)
      const hasProduct = action && action.product
      if (!hasProduct) {
        callback(
          new Error(this.$t('common.forms.rules.field_warnings.required'))
        )
      } else {
        callback()
      }
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
