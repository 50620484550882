<template>
  <th-wrapper
    class="m-8"
    :title="$t('pages.voucher_systems.form.external_system.label')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :md="8">
          <el-form-item prop="name" :label="$t('common.forms.labels.name')">
            <el-input
              id="name"
              v-model="form.name"
              :placeholder="$t('common.forms.labels.name')"
            />
          </el-form-item>
        </el-col>
        <!-- Available in -->
        <el-col :md="8">
          <el-form-item
            for="locations"
            :label="$t('common.forms.labels.locations')"
          >
            <available-in
              id="locations"
              v-model="locations"
              :resources="resources"
              :show-items-limit="2"
            />
          </el-form-item>
        </el-col>
        <!-- Active -->
        <el-col :md="8">
          <el-form-item prop="active" class="mt-8">
            <el-checkbox
              id="active"
              v-model="form.active"
              :aria-label="$t('common.forms.labels.active')"
              :label="$t('common.forms.labels.active')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!-- External system type -->
        <el-col :md="8">
          <el-form-item
            prop="external_system_type"
            :label="
              $t(
                'pages.voucher_systems.form.properties.external_system_type.label'
              )
            "
          >
            <el-select
              id="external_system_type"
              v-model="form.external_system_type"
              class="w-full"
              :placeholder="
                $t(
                  'pages.voucher_systems.form.properties.external_system_type.placeholder'
                )
              "
              clearable
            >
              <el-option
                v-for="item in avaialbleExternalTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- External system id -->
        <el-col :md="8">
          <el-form-item
            prop="external_system_id"
            :label="
              $t(
                'pages.voucher_systems.form.properties.external_system_id.label'
              )
            "
          >
            <el-input
              id="external_system_id"
              v-model="form.external_system_id"
              :placeholder="
                $t(
                  'pages.voucher_systems.form.properties.external_system_id.placeholder'
                )
              "
              clearable
            />
          </el-form-item>
        </el-col>
        <!-- External sub system id -->
        <el-col :md="8">
          <el-form-item
            prop="external_voucher_system_id"
            :label="
              $t(
                'pages.voucher_systems.form.properties.external_voucher_system_id.label'
              )
            "
          >
            <el-input
              id="external_voucher_system_id"
              v-model="form.external_voucher_system_id"
              :placeholder="
                $t(
                  'pages.voucher_systems.form.properties.external_voucher_system_id.placeholder'
                )
              "
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import AvailableIn from '@/components/available-in'

export default {
  components: {
    AvailableIn
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      avaialbleExternalTypes: [
        {
          value: 'tillhub-voucher-api',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.tillhub_voucher_api.label'
          )
        },
        {
          value: 'fleurop',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.fleurop.label'
          )
        },
        {
          value: 'zalando',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.zalando.label'
          )
        }
      ]
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    locations: {
      get() {
        return {
          locations: this.form.branches, //component uses locations but voucher-system still support legacy branches
          branch_groups: this.form.branch_groups
        }
      },
      set(value) {
        this.form.branches = value.locations
        this.form.branch_groups = value.branch_groups
      }
    },
    rules() {
      return {
        name: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        }
      }
    }
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
