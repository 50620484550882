import * as uuid from 'uuid'

export const ACTION_TYPES = ['decrement', 'increment', 'create']

export function generateDefault() {
  return {
    actions: ACTION_TYPES.map((type) => genInitialAction(type)),
    active: true,
    branch_groups: null,
    branches: null,
    external_system_id: null,
    external_system_type: null,
    external_voucher_system_id: null,
    external: true,
    name: null
  }
}

export function genInitialAction(type = null) {
  return {
    id: uuid.v4(),
    name: type,
    type,
    lookup: 'code',
    product: null
  }
}
